import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import MembershipForm from './components/ApplyMembership';

const App = () => {
  const [showMembershipForm, setShowMembershipForm] = useState(false);

  const handleEnter = () => {
    setShowMembershipForm(true);
  };

  const handleClose = () => {
    setShowMembershipForm(false);
  };

  const handleSubmit = (data: any) => {
    console.log('Form submitted:', data);
    setShowMembershipForm(false);
  };
//update 
  return (
    <Router>
      <LandingPage onEnter={handleEnter} />
      {showMembershipForm && (
        <MembershipForm
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
    </Router>
  );
};

export default App;
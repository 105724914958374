import React, { useState } from 'react';
import { ChevronLeft, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface PersonalInfo {
  firstName: string;
  lastName: string;
  identity: string;
  dob: string;
  email: string;
  location: string;
}

interface AdditionalInfo {
  jobTitle: string;
  interests: string[];
  otherClubs: string;
  instagramHandle: string;
  reference: string;
  contribution: string;
}

interface PaymentInfo {
  cardNumber: string;
  expiryDate: string;
  country: string;
}

type FormData = {
  personalInfo: PersonalInfo;
  additionalInfo: AdditionalInfo;
  paymentInfo: PaymentInfo;
}

interface MembershipFormProps {
  onClose: () => void;
  onSubmit: (data: FormData) => void;
}

const INITIAL_FORM_DATA: FormData = {
  personalInfo: {
    firstName: '',
    lastName: '',
    identity: '',
    dob: '',
    email: '',
    location: '',
  },
  additionalInfo: {
    jobTitle: '',
    interests: [],
    otherClubs: '',
    instagramHandle: '',
    reference: '',
    contribution: '',
  },
  paymentInfo: {
    cardNumber: '',
    expiryDate: '',
    country: '',
  },
};

const InterestPill = ({ interest, onRemove }: { interest: string; onRemove: () => void }) => (
  <div className="inline-flex items-center gap-1 px-3 py-1 bg-gray-100 rounded-full text-sm">
    {interest}
    <button onClick={onRemove} className="hover:text-gray-700">
      <X className="w-3 h-3" />
    </button>
  </div>
);

const MembershipForm: React.FC<MembershipFormProps> = ({ onClose, onSubmit }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_DATA);
  const [currentInterest, setCurrentInterest] = useState('');
  const [subscribeToUpdates, setSubscribeToUpdates] = useState(false);
  const navigate = useNavigate();

  const addInterest = (interest: string) => {
    if (interest.trim() && !formData.additionalInfo.interests.includes(interest.trim())) {
      updateAdditionalInfo('interests', [...formData.additionalInfo.interests, interest.trim()]);
      setCurrentInterest('');
    }
  };

  const removeInterest = (indexToRemove: number) => {
    updateAdditionalInfo(
      'interests',
      formData.additionalInfo.interests.filter((_, index) => index !== indexToRemove)
    );
  };

  const updatePersonalInfo = (field: keyof PersonalInfo, value: string) => {
    setFormData(prev => ({
      ...prev,
      personalInfo: {
        ...prev.personalInfo,
        [field]: value
      }
    }));
  };

  const updateAdditionalInfo = (field: keyof AdditionalInfo, value: string | string[]) => {
    setFormData(prev => ({
      ...prev,
      additionalInfo: {
        ...prev.additionalInfo,
        [field]: value
      }
    }));
  };

  const handleContinue = () => {
    if (step < 4) {
      setStep(step + 1);
    } else {
      onSubmit({ ...formData,  });
      navigate('/');
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-[#faf9f7] z-50 overflow-y-auto">
      <div className="max-w-2xl mx-auto px-6 py-8">
        <div className="flex justify-between items-center mb-12">
          <span className="text-red-800 font-serif text-2xl fixed left-8 top-8">Avenida</span>
        </div>

        <div className="mb-24">
          {step === 1 && (
            <div className="space-y-8">
              <h1 className="text-4xl font-serif text-center">Membership</h1>
              <p className="text-sm text-center text-gray-600 max-w-md mx-auto">
                Please fill out all the fields carefully in order for this application to
                be submitted to the Committee. Any applications made without all fields will be
                deemed incomplete and therefore not to be considered.
              </p>
            </div>
          )}

          {step === 2 && (
            <div className="space-y-6">
              <h1 className="text-4xl font-serif">Getting to know you</h1>
              <div className="space-y-4">
                <input
                  type="text"
                  placeholder="Name"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.personalInfo.firstName}
                  onChange={(e) => updatePersonalInfo('firstName', e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Last name"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.personalInfo.lastName}
                  onChange={(e) => updatePersonalInfo('lastName', e.target.value)}
                />
                <select
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.personalInfo.identity}
                  onChange={(e) => updatePersonalInfo('identity', e.target.value)}
                >
                  <option value="">How do you identify?</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                <input
                  type="date"
                  placeholder="DOB"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.personalInfo.dob}
                  onChange={(e) => updatePersonalInfo('dob', e.target.value)}
                />
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="space-y-6">
              <h1 className="text-4xl font-serif">Getting to know you</h1>
              <div className="space-y-4">
                <input
                  type="text"
                  placeholder="Job role / title"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.additionalInfo.jobTitle}
                  onChange={(e) => updateAdditionalInfo('jobTitle', e.target.value)}
                />
                <div className="space-y-2">
                  <div className="flex flex-wrap gap-2">
                    {formData.additionalInfo.interests.map((interest, index) => (
                      <InterestPill
                        key={index}
                        interest={interest}
                        onRemove={() => removeInterest(index)}
                      />
                    ))}
                  </div>
                  <input
                    type="text"
                    placeholder="Interests"
                    className="w-full p-3 border border-gray-200 rounded-md bg-white"
                    value={currentInterest}
                    onChange={(e) => setCurrentInterest(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        addInterest(currentInterest);
                      }
                    }}
                  />
                </div>
                <select
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.additionalInfo.otherClubs}
                  onChange={(e) => updateAdditionalInfo('otherClubs', e.target.value)}
                >
                  <option value="">Are you a member of any other private clubs?</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                <input
                  type="text"
                  placeholder="What is your Instagram handle?"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white"
                  value={formData.additionalInfo.instagramHandle}
                  onChange={(e) => updateAdditionalInfo('instagramHandle', e.target.value)}
                />
                <textarea
                  placeholder="Finally, what do you bring to Avenida?"
                  className="w-full p-3 border border-gray-200 rounded-md bg-white h-32 resize-none"
                  value={formData.additionalInfo.contribution}
                  onChange={(e) => updateAdditionalInfo('contribution', e.target.value)}
                />
              </div>
            </div>
          )}

          {step === 4 && (
            <div className="space-y-6">
              <h1 className="text-4xl font-serif text-center">Thank you!</h1>
              <p className="text-center text-gray-600">We'll be in touch</p>
              <div className="flex items-center gap-2 justify-center mt-8">
                <input
                  type="checkbox"
                  id="subscribe"
                  checked={subscribeToUpdates}
                  onChange={(e) => setSubscribeToUpdates(e.target.checked)}
                  className="rounded border-gray-300"
                />
                <label htmlFor="subscribe" className="text-sm text-gray-600">
                  Subscribe to receive updates on special events
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Updated footer navigation */}
      <div className="fixed bottom-0 left-0 right-0 bg-[#faf9f7] border-t border-gray-100">
        <div className="max-w-md mx-auto px-4 py-6">
          <div className="flex items-center justify-center gap-4 relative">
            <button
              onClick={handleBack}
              className="absolute left-0 flex items-center justify-center text-gray-600"
            >
              <ChevronLeft className="w-8 h-8" />
            </button>
            
            <div className="flex flex-col items-center">
              <button
                onClick={handleContinue}
                className="px-12 py-3 bg-black text-white rounded-full font-medium min-w-[160px]"
              >
                {step === 4 ? 'Complete' : 'Continue'}
              </button>
              <span className="text-sm text-gray-500 mt-2">{step}/4</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipForm;
import { useState } from 'react';
import { X } from 'lucide-react';

interface LandingPageProps {
  onEnter: () => void;
}

const LandingPage = ({ onEnter }: LandingPageProps) => {
  const [activePanel, setActivePanel] = useState<'none' | 'menu' | 'vision' | 'contact'>('none');

  return (
    <div className="w-full min-h-screen bg-[#F0EFE9]">
      {/* Mobile Navigation Panel */}
      <div 
        className={`fixed inset-0 bg-red-900 text-white transform transition-transform duration-300 ease-in-out ${
          activePanel === 'menu' ? 'translate-x-0' : 'translate-x-full'
        } z-50 md:hidden`}
      >
        <div className="p-8">
          <div className="flex justify-between items-center">
            <div className="text-2xl font-serif">Avenida</div>
            <button 
              onClick={() => setActivePanel('none')}
              className="p-2"
              aria-label="Close menu"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
          
          <div className="flex flex-col items-center justify-center h-[80vh] space-y-8">
            <button className="text-lg font-light tracking-wide hover:opacity-80">Home</button>
            <button 
              onClick={() => setActivePanel('vision')}
              className="text-lg font-light tracking-wide hover:opacity-80"
            >
              Vision
            </button>
            <button 
              onClick={() => setActivePanel('contact')}
              className="text-lg font-light tracking-wide hover:opacity-80"
            >
              Contact us
            </button>
            <button 
              onClick={onEnter}
              className="text-lg font-light tracking-wide hover:opacity-80"
            >
              Apply for membership
            </button>
          </div>
        </div>
      </div>

      {/* Vision Panel */}
      <div 
        className={`fixed top-0 bottom-0 right-0 w-full md:w-1/2 bg-[#F7F5EF] transform transition-transform duration-300 ease-in-out ${
          activePanel === 'vision' ? 'translate-x-0' : 'translate-x-full'
        } z-50 overflow-y-auto`}
      >
        <div className="p-8">
          <div className="flex justify-between items-center mb-12">
            <div className="text-red-900 text-2xl font-serif md:hidden">Avenida</div>
            <div className="ml-auto">
              <button 
                onClick={() => setActivePanel('none')}
                className="p-2"
                aria-label="Close vision"
              >
                <X className="w-6 h-6" />
              </button>
            </div>
          </div>

          <div className="mt-12 space-y-8 max-w-xl">
            <h2 className="text-3xl font-serif">Our vision</h2>
            
            <p className="text-gray-800 leading-relaxed font-light">
              Born from the vibrant and elegant spirit of Avenida da Liberdade, our platform emerged from a simple realization: even the most connected travelers struggle to find their tribe in new cities. We've all been there - surrounded by crowds, yet feeling alone in events that just don't resonate.
            </p>

            <p className="text-gray-800 leading-relaxed font-light">
              Avenida bridges this gap, connecting discerning individuals with intimate, curated experiences worldwide. We've created a global community where every event feels like being at your best friend's home - warm, welcoming, and filled with fascinating people.
            </p>

            <p className="text-gray-800 leading-relaxed font-light">
              Our mission is clear: to ensure that wherever you are in the world, you're only a tap away from events that truly speak to you. With Avenida, you're always in the right place, with the right people.
            </p>

            <p className="text-gray-800 leading-relaxed font-light">
              We're more than a platform; we're a passport to the world's most sought-after and authentic local experiences. Whether you're hosting an intimate soirée or seeking an exquisite event abroad, Avenida ensures you're always in good company.
            </p>
          </div>
        </div>
      </div>

      {/* Contact Panel */}
      <div 
        className={`fixed top-0 bottom-0 right-0 w-full md:w-1/2 bg-[#F7F5EF] transform transition-transform duration-300 ease-in-out ${
          activePanel === 'contact' ? 'translate-x-0' : 'translate-x-full'
        } z-50 overflow-y-auto`}
      >
        <div className="p-8">
          <div className="flex justify-between items-center mb-12">
            <div className="text-red-900 text-2xl font-serif md:hidden">Avenida</div>
            <div className="ml-auto">
              <button 
                onClick={() => setActivePanel('none')}
                className="p-2"
                aria-label="Close contact"
              >
                <X className="w-6 h-6" />
              </button>
            </div>
          </div>

          <div className="mt-12 space-y-12 max-w-xl">
            <h2 className="text-3xl font-serif">Get in touch</h2>
            
            <div className="space-y-8">
              <div>
                <h3 className="text-xl font-serif mb-2">General</h3>
                <a href="mailto:info@avenida.com" className="text-gray-800 hover:text-gray-900 font-light">
                  info@avenida.com
                </a>
              </div>

              <div>
                <h3 className="text-xl font-serif mb-2">Membership</h3>
                <a href="mailto:membership@avenida.com" className="text-gray-800 hover:text-gray-900 font-light">
                  membership@avenida.com
                </a>
              </div>

              <div>
                <h3 className="text-xl font-serif mb-2">Press</h3>
                <a href="mailto:press@avenida.com" className="text-gray-800 hover:text-gray-900 font-light">
                  press@avenida.com
                </a>
              </div>

              <div>
                <h3 className="text-xl font-serif mb-2">Partnership</h3>
                <a href="mailto:partnership@avenida.com" className="text-gray-800 hover:text-gray-900 font-light">
                  partnership@avenida.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="min-h-screen flex flex-col">
        {/* Navigation */}
        <nav className="w-full px-4 sm:px-8 py-6">
          <div className="flex justify-between items-center">
            <div className="text-2xl text-red-900 font-serif">
              Avenida
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex gap-12">
              <button 
                onClick={() => setActivePanel('contact')}
                className="relative font-light tracking-wide hover:text-gray-600 group"
              >
                Contact Us
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-red-900 transform scale-x-0 transition-transform group-hover:scale-x-100"></span>
                {activePanel === 'contact' && (
                  <span className="absolute bottom-0 left-0 w-full h-0.5 bg-red-900"></span>
                )}
              </button>
              <button 
                onClick={() => setActivePanel('vision')}
                className="relative font-light tracking-wide hover:text-gray-600 group"
              >
                Vision
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-red-900 transform scale-x-0 transition-transform group-hover:scale-x-100"></span>
                {activePanel === 'vision' && (
                  <span className="absolute bottom-0 left-0 w-full h-0.5 bg-red-900"></span>
                )}
              </button>
              <button 
                onClick={onEnter}
                className="font-light tracking-wide hover:text-gray-600"
              >
                Apply for membership
              </button>
            </div>

            {/* Mobile Menu Button */}
            <button 
              onClick={() => setActivePanel('menu')}
              className="md:hidden p-2"
              aria-label="Toggle menu"
            >
              <svg 
                className="w-6 h-6" 
                fill="none" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </nav>

        {/* Hero Section */}
        <div className="flex-1 flex flex-col items-center mt-12">
          <div className="relative w-full max-w-6xl mx-auto px-4 md:px-6">
            <img 
              src="/pool2.jpeg"
              alt="Exclusive gatherings"
              className="w-full aspect-[3/4] md:aspect-[2.5/1] object-cover rounded-lg"
            />
            <div className="absolute inset-0 flex flex-col items-center justify-end md:justify-center pb-8 md:pb-0">
              <div 
                className={`text-center space-y-5 transition-opacity duration-500 ${
                  activePanel !== 'none' ? 'opacity-0' : 'opacity-100'
                }`}
              >
                <h1 className="text-[22px] font-serif text-black">
                  Exclusive Gatherings
                </h1>
                <p className="text-[22px] font-serif text-black">
                  Like-minded souls
                </p>
                <p className="text-[22px] font-serif text-black">
                  Invitation Only
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;